import React from 'react'
import PropTypes from 'prop-types'
import { DJPageTemplate } from './dj'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'

const DjPageEn = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark

  return (
    <Layout
      page="dj"
      navbarLinks={frontmatter.navbar}
      navbarTitle={frontmatter.title}
      lang="en"
    >
      <DJPageTemplate
        lang="en"
        mainImage={frontmatter.mainImage}
        mainPitch={{
          title: frontmatter.mainPitch.title,
          content: html,
          sideImage: frontmatter.mainPitch.sideImage
        }}
        title={frontmatter.title}
        sections={frontmatter.sections}
        contactForm={frontmatter.contactForm}
      />
    </Layout>
  )
}

DjPageEn.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      html: PropTypes.string
    })
  })
}

export default DjPageEn

export const pageQuery = graphql`
  query DjPageEn {
    markdownRemark(frontmatter: { templateKey: { eq: "dj-en" } }) {
      html
      frontmatter {
        title
        navbar {
          text
          anchor
        }
        mainImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mainPitch {
          title
          description
          sideImage {
            image {
              childImageSharp {
                fluid(maxWidth: 720, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
        sections {
          buttonLabel
          items {
            title
            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            backgroundPosition
          }
        }
        contactForm {
          labelName
          labelEmail
          labelSubject
          labelMessage
          labelSend
        }
      }
    }
  }
`
