import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby-link'

import 'typeface-major-mono-display'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export class DJPageTemplate extends Component {
  state = {
    name: '',
    email: '',
    message: '',
    subject: 'Autre...'
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state
      })
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    const { mainImage, mainPitch, sections, contactForm, lang } = this.props
    const { name, email, subject, message } = this.state

    return (
      <div className="has-background-black">
        <section
          className="hero is-fullheight"
          style={{
            backgroundImage: `url(${
              mainImage.childImageSharp
                ? mainImage.childImageSharp.fluid.src
                : mainImage
            })`,
            backgroundPosition: `top right`,
            backgroundAttachment: `scroll`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }}
        >
          <div className="hero-body center">
            <h1 className="title is-1 is-3-mobile has-text-white">
              <span className="dj-annotation">DJ</span>
              <span className="steve-rumo">Rumo</span>
            </h1>
          </div>
        </section>

        <section id={mainPitch.title} className="section is-spaced-evenly">
          <div className="columns is-vcentered">
            <div className="column is-1" />
            <div className="column is-7">
              <div className="content">
                <div>
                  <h3 className="title is-2 has-text-white">
                    {mainPitch.title}
                  </h3>
                </div>
                <div dangerouslySetInnerHTML={{ __html: mainPitch.content }} />
              </div>
            </div>

            {mainPitch.sideImage && mainPitch.sideImage.image ? (
              <div className="column is-3 is-hidden-mobile">
                <figure className="image is-3by4">
                  <img
                    src={
                      mainPitch.sideImage.image.childImageSharp
                        ? mainPitch.sideImage.image.childImageSharp.fluid.src
                        : mainPitch.sideImage.image
                    }
                    alt={mainPitch.sideImage.alt}
                  />
                </figure>
              </div>
            ) : null}

            <div className="column is-1" />
          </div>
        </section>

        {sections.items.map(section => (
          <section
            id={section.title}
            key={section.title}
            className="section is-spaced-evenly"
            style={
              section.backgroundImage
                ? {
                    backgroundImage: section.backgroundImage.childImageSharp
                      ? `url(${section.backgroundImage.childImageSharp.fluid.src})`
                      : `url(${section.backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: section.backgroundPosition
                      ? section.backgroundPosition
                      : 'center'
                  }
                : null
            }
          >
            <div className="services content">
              <h3
                className="title is-2 has-text-white"
                style={
                  section.backgroundImage
                    ? {
                        margin: 0
                      }
                    : null
                }
              >
                {section.title}
              </h3>
              <button
                className="button"
                onClick={() => {
                  this.setState({
                    subject: section.title
                  })

                  //NOTE: Change to react's refs if needed
                  document.getElementById('name').focus()
                  document.getElementById('contact').scrollIntoView()
                }}
              >
                <span>{sections.buttonLabel}</span>
                <span className="icon">
                  <i className="mdi mdi-arrow-right mdi-36px" />
                </span>
              </button>
            </div>
          </section>
        ))}

        <section id="contact" className="section">
          <div className="columns">
            <div className="column is-2" />
            <div className="column is-8">
              <div className="container is-fluid">
                <h3 className="title is-3 has-text-white has-extra-padding">
                  Contact
                </h3>
                <form
                  name="DJ Steve Rumo"
                  method="post"
                  action={`/${lang !== 'fr' ? lang + '/' : ''}success`}
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={this.handleSubmit}
                >
                  <div hidden>
                    <label>
                      Don’t fill this out:{' '}
                      <input name="bot-field" onChange={this.onChange} />
                    </label>
                  </div>
                  <div className="field">
                    <label htmlFor="subject" className="label has-text-white">
                      {contactForm.labelSubject}
                    </label>
                    <div className="select">
                      <select
                        id="subject"
                        name="subject"
                        value={subject}
                        onChange={this.handleChange}
                      >
                        {sections.items.map(service => (
                          <option key={service.title} value={service.title}>
                            {service.title}
                          </option>
                        ))}
                        <option value="Autre...">Autre...</option>
                      </select>
                    </div>
                  </div>
                  <div className="field">
                    <label htmlFor="name" className="label has-text-white">
                      {contactForm.labelName}
                    </label>
                    <div className="control">
                      <input
                        id="name"
                        name="name"
                        className="input"
                        type="text"
                        required
                        value={name}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label htmlFor="email" className="label has-text-white">
                      {contactForm.labelEmail}
                    </label>
                    <div className="control">
                      <input
                        id="email"
                        name="email"
                        className="input"
                        type="email"
                        required
                        value={email}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label htmlFor="message" className="label has-text-white">
                      {contactForm.labelMessage}
                    </label>
                    <div className="control">
                      <textarea
                        id="message"
                        name="message"
                        className="textarea"
                        required
                        value={message}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="control has-small-extra-padding">
                    <button
                      type="submit"
                      className="button has-background-black has-text-white"
                    >
                      {contactForm.labelSend}
                    </button>
                  </div>
                </form>
                <div className="column is-2" />
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

DJPageTemplate.propTypes = {
  mainImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      backgroundImage: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
      ]),
      backgroundPosition: PropTypes.string
    })
  ),
  contactForm: PropTypes.shape({
    labelName: PropTypes.string,
    labelEmail: PropTypes.string,
    labelSubject: PropTypes.string,
    labelMessage: PropTypes.string,
    labelSend: PropTypes.string
  })
}

export default DJPageTemplate
